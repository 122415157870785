import React from "react";

import "./SmallButton.css";

interface SmallButtonProps {
  text: string;
  icon: string | null;
  isOutlineOnly: boolean;
  onClick: () => void;
  className?: string;
}

const SmallButton: React.FC<SmallButtonProps> = ({
  text,
  onClick,
  icon,
  isOutlineOnly,
  className,
}) => {
  if (isOutlineOnly)
    return (
      <button className={`small-button-outline ${className}`} onClick={onClick}>
        {icon != null && (
          <img src={icon} alt="Icon" className="small-button-icon-outline" />
        )}

        {text}
      </button>
    );
  return (
    <button className={`small-button ${className}`} onClick={onClick}>
      {icon != null && (
        <img src={icon} alt="Icon" className="small-button-icon" />
      )}

      {text}
    </button>
  );
};

export default SmallButton;
