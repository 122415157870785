import React, { useEffect, useRef, useState } from "react";
import Select, { MultiValue } from "react-select";
import LargeButton from "../../../input/LargeButton";
import { PlaceDetail } from "../../../../model/poi";
import { apiGenerateFollowUp, apiGenerateMulti, apiGenerateSingle, apiGetPois, apiGetPoisBoundingBox } from "../../../../api/api";
import StoryResponse from "../../../../model/story-response";
import { mapsApiKey, webSocketBaseUrl } from "../../../../constants";
import { getUserToken } from "../../../../firebase/firebase";
import { GoogleMap, InfoWindowF, LoadScript, MarkerF } from "@react-google-maps/api";
import locationIcon from "../../../../assets/location.png";
import { base64ToArrayBuffer, calculateDistance, getSampleRate, processPCMData } from "./hostPlayerMap.util";
import { PersonaModel } from "../../../../model/persona";

export enum StoryType {
    SURPRISE_ME = "SURPRISE_ME",
    SELECT_POI = "SELECT_POI",
}
interface HostPlayerMapProps {
    dropdownStyles: any;
    pendingSave: boolean;
    selectedPoiTypes: MultiValue<OptionType>;
    host: PersonaModel;
    storyType: StoryType;
}
interface OptionType {
    value: string;
    label: string;
}
interface FollowUp {
    id: number;
    content: string;
}
interface WebSocketData {
    type: "AUDIO" | "TRANSCRIPT" | "INFO";
    payload: Payload;
}
interface Payload {
    audioChunk?: AudioChunk;
    textChunk?: string;
    title?: string;
    audioUrl?: string;
    followUps?: FollowUp[];
    level?: number;
}
interface AudioChunk {
    data: number[];
}
interface MarkerType {
    id: number;
    position: {
        lat: number;
        lng: number;
    };
    name: string;
    poi: PlaceDetail;
}
interface Location {
    lat: number;
    lng: number;
}
export const defaultCenter = { lat: 40.712776, lng: -74.005974 };
const generationTypeOptions = [
    {
        label: "Streaming - Listen while generating",
        value: "streaming",
    },
    {
        label: "Basic - Generate entire story",
        value: "basic",
    },
];


const HostPlayerMap: React.FC<HostPlayerMapProps> = ({ dropdownStyles, pendingSave, selectedPoiTypes, host, storyType: generationType }) => {
    const [selectedGenType, setGenType] = useState<OptionType | null>(generationTypeOptions[0]);
    const [mapCenter, setMapCenter] = useState(defaultCenter);

    const [loading, setLoading] = useState(false);
    const lastGenerationStartTime = useRef(Date.now());
    const [generationTime, setGenerationTime] = useState(Date.now());

    const [testStory, setTestStory] = useState<StoryResponse | null>(null);
    const [parentStory, setParentStory] = useState<StoryResponse | null>(null);
    const [streamDownloadUrl, setStreamDownloadUrl] = useState<string | null>("");
    const [placeDetails, setPlaceDetails] = useState<PlaceDetail[]>([]);
    const [lvl, setLvl] = useState(0);

    const [streamTestTranscript, setStreamTestTranscript] = useState("");
    const [streamStoryTitle, setStreamStoryTitle] = useState("");
    const [streamFollowups, setStreamFollowups] = useState<FollowUp[] | null>(
        null
    );

    useEffect(() => {
        if (selectedGenType) {
            console.log("Selected Generation Type changed:", selectedGenType);
            setTestStory(null);
            setPlaceDetails([]);
        }
    }, [selectedGenType]);


    const pendingRequest = useRef<(() => void) | null>();
    const audioBufferQueue = useRef<AudioBuffer[]>([]);
    const audioBufferIndex = useRef(0);
    const isBuffering = useRef<boolean>(false);
    const audioContext = useRef<AudioContext>(); //new (window.AudioContext || (window as any).webkitAudioContext)();
    const playbackIndex = useRef(0);
    const socketRef = useRef<WebSocket | null>(null);
    const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
    const [markers, setMarkers] = useState<MarkerType[]>([]);
    const mapRef = useRef<google.maps.Map>();
    const [selectedMarker, setSelectedMarker] = useState<MarkerType | null>(null);

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error("Error obtaining location:", error);
                    // Handle error or set a default location
                    setCurrentLocation(defaultCenter); // Default to New York City
                }
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
            // Set a default location
            setCurrentLocation(defaultCenter); // Default to New York City
        }
    }, []);

    const handleLoad = (map: google.maps.Map) => {
        mapRef.current = map;
    };
    const handleCenterChanged = () => {
        // Check if mapRef is current and accessible
        if (mapRef.current) {
            const newCenter = mapRef.current.getCenter();
            setMapCenter({
                lat: newCenter?.lat() ?? mapCenter.lat,
                lng: newCenter?.lng() ?? mapCenter.lng,
            });
        }
    };
    const handleInfoWindowClose = () => {
        setSelectedMarker(null);
    };
    const handleMarkerClick = (marker: MarkerType) => {
        setSelectedMarker(marker);
    };

    async function updateMapPOIs() {
        if (currentLocation == null || currentLocation === undefined) return;
        try {
            const places: PlaceDetail[] = await apiGetPoisBoundingBox(
                mapCenter.lat,
                mapCenter.lng,
                selectedPoiTypes.map((it) => it.value)
            );

            var newMarkers = [];
            for (let index = 0; index < places.length; index++) {
                const place = places[index];
                newMarkers.push({
                    id: index,
                    position: {
                        lat: place.location.latitude,
                        lng: place.location.longitude,
                    },
                    name: place.displayName.text,
                    poi: place,
                });
            }
            setMarkers(newMarkers);
        } catch (error) { }
    }

    const initializeWebSocket = (
        url: string,
        token: string,
        callback?: () => void
    ) => {
        if (!token) {
            alert("Please enter a token");
            return;
        }

        if (audioContext.current === undefined) {
            audioContext.current = new (window.AudioContext || (window as any).webkitAudioContext)();
        }

        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            console.log("WebSocket already open");
            callback?.();
            return;
        }
        //@ts-ignore pass token in websocket protocol as a hacky way. JS Websocket doesn't support headers
        socketRef.current = new WebSocket(url, [token.replace("Bearer ", "")]);

        socketRef.current.onopen = () => {
            console.log("Connected to WebSocket server");
            callback?.();
        };

        socketRef.current.onclose = () => {
            console.log("Disconnected from WebSocket server");
            socketRef.current = null;
        };

        socketRef.current.onmessage = (event) => {
            console.log("Message", event.data);

            if (typeof event.data === "string") {
                const data: WebSocketData = JSON.parse(event.data);
                setLoading(false);
                if (data.type === "AUDIO") {
                    handleAudio(data.payload.audioChunk!, "audio/mpeg", "pcm_44100");
                } else if (data.type === "TRANSCRIPT") {
                    setStreamTestTranscript(
                        (prevTranscript) => prevTranscript + data.payload.textChunk
                    );
                } else if (data.type === "INFO") {
                    handleInfo(data.payload);
                }
            }
        };
    };

    function handleAudio(
        audioChunk: AudioChunk,
        audioFormat: string,
        outputFormat: string
    ) {
        const buffer = new Uint8Array(audioChunk.data);
        const blob = new Blob([buffer], { type: audioFormat });
        const reader = new FileReader();
        reader.onload = () => {
            if (!audioContext.current) return;

            const data: { audio: string } = JSON.parse(reader.result as string);
            if (!data.audio) {
                return;
            }
            const arrayBuffer = base64ToArrayBuffer(data.audio);
            if (!arrayBuffer || arrayBuffer.byteLength === 0) {
                return;
            }
            const currentIndex = audioBufferIndex.current++;
            const pcmData = processPCMData(arrayBuffer);
            if (pcmData) {
                const sampleRate = getSampleRate(outputFormat);
                const audioBuffer = audioContext.current.createBuffer(
                    1,
                    pcmData.length,
                    sampleRate
                );
                audioBuffer.copyToChannel(pcmData, 0);
                audioBufferQueue.current[currentIndex] = audioBuffer;
                if (!isBuffering.current) {
                    playAudioBuffer();
                }
            }
        };
        reader.readAsText(blob);
    }

    function playAudioBuffer() {
        if (!isBuffering.current && audioBufferQueue.current.length > playbackIndex.current && audioContext.current) {
            isBuffering.current = true;
            const buffer = audioBufferQueue.current[playbackIndex.current];
            const source = audioContext.current.createBufferSource();
            source.buffer = buffer;
            source.connect(audioContext.current.destination);
            source.start(0);
            source.onended = () => {
                playbackIndex.current++;
                isBuffering.current = false;
                playAudioBuffer();
            };
        } else {
            isBuffering.current = false;
        }
    }
    function handleInfo(payload: Payload) {
        if (payload.title) {
            setStreamStoryTitle(payload.title);
        }
        if (payload.level) {
            setLvl(payload.level);
        }
        if (payload.followUps) {
            setStreamFollowups(payload.followUps);
            setGenerationTime(Date.now() - lastGenerationStartTime.current);
        }
        if (payload.audioUrl) {
            setStreamDownloadUrl(payload.audioUrl);
        }
    }

    async function generateTestStoryFromMulti() {
        setLoading(true);
        lastGenerationStartTime.current = Date.now();
        setStreamDownloadUrl(null);
        setGenerationTime(Date.now());
        try {
            const places: PlaceDetail[] = await apiGetPois(
                mapCenter.lat,
                mapCenter.lng,
                5,
                selectedPoiTypes.map((it) => it.value)
            );
            setPlaceDetails(places);
            if (places.length === 0) {
                alert("Zero POIs found.");
                return;
            }

            if (selectedGenType!.value === "basic") {
                const testStory = await apiGenerateMulti({
                    latitude: mapCenter.lat,
                    longitude: mapCenter.lng,
                    personaId: host.id,
                    test: true,
                    pois: places,
                });
                setTestStory(testStory.data);
                setParentStory(testStory.data);
                setLvl(0);
                setGenerationTime(Date.now() - lastGenerationStartTime.current);
                setLoading(false);
                return;
            } else if (selectedGenType!.value === "streaming") {
                var payload = {
                    settings: {
                        outputFormat: "pcm_44100",
                    },
                    location: {
                        latitude: mapCenter.lat,
                        longitude: mapCenter.lng,
                    },
                    personaId: host.id,
                    test: true,
                    pois: places,
                };

                streamGenerateRabbitholePOIMulti(payload);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    async function streamGenerateRabbitholePOIMulti(data: any) {
        setStreamTestTranscript("");
        setStreamFollowups(null);

        pendingRequest.current = () => {
            socketRef.current!.send(
                JSON.stringify({ event: "generate-rabbithole-poi-multi", data })
            );
        };

        if (!(socketRef.current && socketRef.current.readyState === WebSocket.OPEN)) {
            initializeWebSocket(
                webSocketBaseUrl + "/rabbithole",
                await getUserToken(),
                pendingRequest.current
            );
        } else {
            pendingRequest.current();
        }
    }

    async function generateTestStoryFromSingle(poi: PlaceDetail) {
        setLoading(true);
        lastGenerationStartTime.current = Date.now();
        setStreamDownloadUrl(null);
        setGenerationTime(Date.now());
        setPlaceDetails([poi]);

        if (selectedGenType!.value === "basic") {
            try {
                const testStory = await apiGenerateSingle({
                    latitude: mapCenter.lat,
                    longitude: mapCenter.lng,
                    personaId: host.id,
                    test: true,
                    poi: poi,
                });
                setTestStory(testStory.data);
                setParentStory(testStory.data);
                setLvl(0);
                setGenerationTime(Date.now() - lastGenerationStartTime.current);
                setLoading(false);
            } catch (error) {
                console.log("error", error);
            }
        } else if (selectedGenType!.value === "streaming") {
            var payload = {
                settings: {
                    outputFormat: "pcm_44100",
                },
                location: {
                    latitude: mapCenter.lat,
                    longitude: mapCenter.lng,
                },
                personaId: host.id,
                test: true,
                poi: poi,
            };
            streamGenerateRabbitholePOISingle(payload);
        }
    }

    async function streamGenerateRabbitholePOISingle(data: any) {
        setStreamTestTranscript("");
        setStreamFollowups(null);

        pendingRequest.current = () =>
            socketRef.current!.send(
                JSON.stringify({ event: "generate-rabbithole-poi-single", data: data })
            );

        if (!socketRef.current || socketRef.current.readyState !== WebSocket.OPEN) {
            initializeWebSocket(
                webSocketBaseUrl + "/rabbithole",
                await getUserToken(),
                pendingRequest.current
            );
        } else {
            pendingRequest.current();
        }
    }

    async function generateTestStoryFromFollowUp(
        followupId: number,
        parentId: number
    ) {
        setLoading(true);
        lastGenerationStartTime.current = Date.now();
        setStreamDownloadUrl(null);
        setGenerationTime(Date.now());

        if (selectedGenType!.value === "basic") {
            try {
                const testStory = await apiGenerateFollowUp(parentId, followupId);

                setTestStory(testStory.data);
                setLvl(lvl + 1);
            } catch (error) {
                console.log("error", error);
            }
            setGenerationTime(Date.now() - lastGenerationStartTime.current);
            setLoading(false);
        } else if (selectedGenType!.value === "streaming") {
            var payload = {
                settings: {
                    outputFormat: "pcm_44100",
                },
                followUpId: followupId,
                test: true,
            };
            streamGenerateRabbitholeFollowUp(payload);
        }
    }
    async function streamGenerateRabbitholeFollowUp(data: any) {
        setStreamTestTranscript("");
        setStreamFollowups(null);

        pendingRequest.current = () =>
            socketRef.current!.send(
                JSON.stringify({ event: "generate-rabbithole-followup", data })
            );

        if (!socketRef.current || socketRef.current.readyState !== WebSocket.OPEN) {
            initializeWebSocket(
                webSocketBaseUrl + "/rabbithole",
                await getUserToken(),
                pendingRequest.current
            );
        } else {
            pendingRequest.current();
        }
    }

    return <>
        <h1>TEST STORY GENERATION</h1>
        <div className="row">
            <div>
                <Select
                    value={selectedGenType}
                    onChange={(newValue) => {
                        setGenType(newValue);
                    }}
                    options={generationTypeOptions}
                    styles={dropdownStyles}
                />
                <br></br>
                {loading && (
                    <p className="blink-opacity">Generating story..</p>
                )}
                {!loading && generationType == StoryType.SURPRISE_ME && (
                    <LargeButton
                        text={"SURPRISE ME"}
                        icon={null}
                        color="#34A853"
                        onClick={function (): void {
                            if (pendingSave) {
                                alert("Please save changes before generating a test story.");
                                return;
                            }
                            generateTestStoryFromMulti();
                        }} />
                )}
                {!loading && generationType == StoryType.SELECT_POI && (
                    <LargeButton
                        text={"SEARCH POIs"}
                        icon={null}
                        color="#34A853"
                        onClick={function (): void {
                            if (pendingSave) {
                                alert("Please save changes before generating a test story.");
                                return;
                            }
                            updateMapPOIs();
                        }} />
                )}
            </div>
            <div></div>
        </div>
        <div className="row">
            <div>
                <div style={{ position: "relative", width: "100%", height: 600 }}>
                    <LoadScript googleMapsApiKey={mapsApiKey}>
                        <GoogleMap
                            mapContainerStyle={{
                                width: "100%",
                                height: 600,
                                borderRadius: 8,
                                overflow: "hidden",
                            }}
                            onLoad={handleLoad}
                            onCenterChanged={handleCenterChanged}
                            center={currentLocation || defaultCenter}
                            zoom={10}
                        >
                            {selectedMarker && (
                                <InfoWindowF
                                    key={"infowindow"}
                                    position={selectedMarker.position}
                                    onCloseClick={handleInfoWindowClose}
                                >
                                    <div>
                                        <h4 style={{ color: "black" }}>{selectedMarker.name}</h4>
                                        <LargeButton
                                            text={"START RABBIT HOLE"}
                                            icon={null}
                                            color="#34A853"
                                            onClick={function (): void {
                                                if (pendingSave) {
                                                    alert(
                                                        "Please save changes before generating a test story."
                                                    );
                                                    return;
                                                }
                                                if (!loading)
                                                    generateTestStoryFromSingle(selectedMarker.poi);
                                            }}
                                        ></LargeButton>
                                    </div>
                                </InfoWindowF>
                            )}
                            {markers.map((marker) => {
                                return (
                                    <MarkerF
                                        key={marker.id}
                                        position={marker.position}
                                        onClick={() => handleMarkerClick(marker)}
                                    />
                                );
                            })}
                        </GoogleMap>
                    </LoadScript>
                    {selectedMarker == null && (
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                width: "30px", // Adjust the size as necessary
                                height: "30px", // Adjust the size as necessary
                                backgroundImage: `url(${locationIcon})`, // Use imported image
                                backgroundSize: "cover", // Ensures the image covers the div completely
                                transform: "translate(-50%, -50%)",
                                pointerEvents: "none", // Allows click events to pass through to the map
                            }}
                        />
                    )}
                </div >
            </div >
            <div></div>
        </div>
        {!loading && placeDetails.length > 0 && (
            <div className="row" style={{ background: "#34A8531A", margin: "0 -50px -50px -50px", padding: "0 50px 50px 50px" }}>
                {selectedGenType?.value === "streaming" &&
                    streamTestTranscript.length > 0 && (
                        <div>
                            <h2>{streamStoryTitle ? "LVL " + lvl + " - " + streamStoryTitle : "Streaming your story.."}</h2>
                            <p>{streamTestTranscript}</p>
                            {generationTime < 999999 && (
                                <p>{"Generating took " + generationTime / 1000 + " seconds"}</p>
                            )}

                            {streamFollowups && streamFollowups.length > 0 ? (
                                <>
                                    <br />
                                    <p>Continue rabbit hole with topic:</p>
                                    <div className="side-by-side">
                                        {streamFollowups.map((followUp, index) => (
                                            <LargeButton
                                                key={index}
                                                text={followUp.content}
                                                icon={null}
                                                color="#34A853"
                                                onClick={function (): void {
                                                    if (pendingSave) {
                                                        alert(
                                                            "Please save changes before generating a test story."
                                                        );
                                                        return;
                                                    }
                                                    generateTestStoryFromFollowUp(followUp.id, 0);
                                                }}
                                            ></LargeButton>
                                        ))}
                                    </div>
                                    <br />
                                    <br />
                                </>
                            ) : (
                                <small>
                                    Followups will appear here once the story has fully
                                    generated..
                                </small>
                            )}
                            <br />
                            {streamDownloadUrl != null && (
                                <LargeButton
                                    text={"Download .mp3"}
                                    icon={null}
                                    color="#cccccc"
                                    onClick={function (): void {
                                        const downloadLink = document.createElement("a");
                                        downloadLink.href = streamDownloadUrl;
                                        downloadLink.download = streamStoryTitle + ".mp3";
                                        downloadLink.click();
                                    }}
                                ></LargeButton>
                            )}
                        </div>
                    )
                }
                {selectedGenType?.value === "basic" &&
                    placeDetails.length > 0 && (
                        <div>
                            <h2>{"LVL " + lvl + " - " + testStory?.title}</h2>
                            <p style={{ paddingTop: 12 }}>{testStory?.transcript}</p>
                            <br />

                            {testStory && selectedGenType?.value === "basic" && (
                                <>
                                    <audio controls src={testStory.audioUrl}>
                                        Your browser does not support the audio element.
                                    </audio>
                                    <a
                                        className="download-button"
                                        href={testStory.audioUrl}
                                        download
                                        target="_blank"
                                    >
                                        <button>Download</button>
                                    </a>
                                </>
                            )}
                            {generationTime < 999999 && (
                                <p>{"Generating took " + generationTime / 1000 + " seconds"}</p>
                            )}
                            <br />
                            {parentStory && testStory && (
                                <>
                                    <p>Continue rabbit hole with topic:</p>
                                    <div className="side-by-side">
                                        {testStory.followUps.length > 0 &&
                                            testStory.followUps.map((followUp, index) => (
                                                <LargeButton
                                                    key={index}
                                                    text={followUp.content}
                                                    icon={null}
                                                    color="#34A853"
                                                    onClick={function (): void {
                                                        if (pendingSave) {
                                                            alert(
                                                                "Please save changes before generating a test story."
                                                            );
                                                            return;
                                                        }
                                                        generateTestStoryFromFollowUp(followUp.id, parentStory!.id);
                                                    }}
                                                ></LargeButton>
                                            ))}
                                    </div>
                                </>
                            )}
                        </div>
                    )
                }
                <div>
                    <h1>Location info</h1>
                    {placeDetails.map((place, index) => (
                        <div key={index}>
                            <b>{(index + 1).toString() + ". " + place.displayName.text}</b>
                            <br />
                            <small>{place.formattedAddress}</small>
                            <br />
                            <small>
                                {"Distance " +
                                    calculateDistance(
                                        mapCenter.lat,
                                        mapCenter.lng,
                                        place.location.latitude,
                                        place.location.longitude
                                    )}
                            </small>
                            <br />
                            <small>{place.types.join(", ")}</small>
                            <br />
                            <br />
                        </div>
                    ))}
                </div>
            </div>
        )}
    </>;
}

export default HostPlayerMap;