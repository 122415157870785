import React, { CSSProperties } from "react";

import "./LargeButton.css";

interface LargeButtonProps {
  text: string;
  icon: string | null;
  color: string | null;
  outlineOnly?: boolean;
  fillWidth?: boolean;
  onClick: () => void;
  disabled?: boolean;
  style?: CSSProperties;
  loading?: boolean;
}

const LargeButton: React.FC<LargeButtonProps> = ({
  text,
  onClick,
  icon,
  color,
  outlineOnly = false,
  fillWidth = false,
  disabled = false,
  style = {},
  loading = false
}) => {
  return (
    <button
      className={
        (outlineOnly ? "large-button-outline" : "large-button") +
        " " +
        (fillWidth ? "large-button-fillwidth" : "") +
        " " +
        (loading ? "loading" : "")
      }
      onClick={onClick}
      style={color != null && !outlineOnly ? { backgroundColor: color, ...style } : { ...style }}
      disabled={disabled || loading}
    >
      <div className="large-button-content">{icon != null && <img src={icon} alt="Icon" className="button-icon" />}
        {text}
      </div>
      <span className="loading">Loading<span>.</span><span style={{ animationDelay: "0.2s" }}>.</span><span style={{ animationDelay: "0.4s" }}>.</span></span>
    </button>
  );
};

export default LargeButton;
