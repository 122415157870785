import React, { useState, useRef } from 'react';
import './Collapsible.css';
import dropdownIcon from "../../assets/dropdown.png";

interface CollapsibleProps {
    buttonText: string;
    children: React.ReactNode;
}

const Collapsible: React.FC<CollapsibleProps> = ({ buttonText, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleCollapsible = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="collapsible">
            <button onClick={toggleCollapsible} className='collapsible-button'>
                {buttonText} <img src={dropdownIcon} alt="dropdown" className={`dropdown-icon ${isOpen ? 'open' : ''}`} height={7} />
            </button>
            <div
                ref={contentRef}
                className={`collapsible-content ${isOpen ? 'open' : ''}`}
                style={{ maxHeight: isOpen ? contentRef.current?.scrollHeight : 0 }}
            >
                {children}
            </div>
        </div>
    );
};

export default Collapsible;
