import { poiPost } from "./method-poi";
import { Location, ReverseGeocodeResponse } from "./response";

export const poiEndpoints = {
  PG_REVERSE_GEOCODE: "/v1/admin/playground/reverse-geocode",
  PG_WEBSEARCH: "/v1/admin/playground/websearch",
  PG_GENERATE_STORY: "/v1/admin/playground/generate-story",
};

export const poiPGReverseGeocode = async (body: { location: Location }) => {
  return await poiPost<ReverseGeocodeResponse>(
    poiEndpoints.PG_REVERSE_GEOCODE,
    body
  );
};

export const poiPGWebsearch = async (body: {
  query: string;
  searchTopic: string;
  includeDomains: string[];
  excludeDomains: string[];
}) => {
  return await poiPost<any>(poiEndpoints.PG_WEBSEARCH, body);
};

export const poiPGGenerateStory = async (body: {
  promptSystem: string;
  promptUser: string;
}) => {
  return await poiPost<any>(poiEndpoints.PG_GENERATE_STORY, body);
};

export const poiPGGooglePOIsInRadius = async (body: {
  location: { latitude: number; longitude: number };
  poiTypes: string[];
}) => {
  return await poiPost<any>("/v1/admin/playground/pois-in-radius", body);
};

export const poiPGAugmentPOIsWithWebsearch = async (body: {
  query: string;
  searchTopic: string;
  includeDomains: string[];
  excludeDomains: string[];
  pois: any[];
}) => {
  return await poiPost<any>(
    "/v1/admin/playground/augment-pois-with-websearch",
    body
  );
};

export const poiPGGenerateFollowUpSuggestions = async (body: {
  promptSystem: string;
  promptUser: string;
}) => {
  return await poiPost<any>(
    "/v1/admin/playground/generate-follow-up-suggestions",
    body
  );
};

export const poiPGGenerateFollowUpStory = async (body: {
  promptSystem: string;
  promptUser: string;
}) => {
  return await poiPost<any>(
    "/v1/admin/playground/generate-follow-up-story",
    body
  );
};

export const poiGooglePOIsInRadius = async (body: {
  location: { latitude: number; longitude: number };
  poiTypes: string[];
}) => {
  return await poiPost<any>("/v1/nearby/in-radius", body);
};
