import React, { useEffect, useRef, useState } from "react";
import { PersonaModel } from "../../../../model/persona";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { mapsApiKey } from "../../../../constants";
import { defaultCenter } from "../../player/hostPlayerMap/hostPlayerMap";
import LargeButton from "../../../input/LargeButton";
import { poiPGGenerateFollowUpSuggestions, poiPGGenerateStory, poiPGReverseGeocode, poiPGWebsearch } from "../../../../api/poi/poi";
import Select from "react-select";
import { dropdownStyles, OptionType, webSearchTopicOptions } from "../../../../routes/HostPage";

interface HostPlaygroundWebsearchProps {
    host: PersonaModel;
}
export interface Location {
    lat: number;
    lng: number;
}

const HostPlaygroundWebsearch: React.FC<HostPlaygroundWebsearchProps> = ({ host }) => {
    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const mapRef = useRef<google.maps.Map | null>(null);
    const handleLoad = (map: google.maps.Map) => {
        mapRef.current = map;
    };
    const handleCenterChanged = () => {
        // Check if mapRef is current and accessible
        if (mapRef.current) {
            const newCenter = mapRef.current.getCenter();
            setMapCenter({
                lat: newCenter?.lat() ?? mapCenter.lat,
                lng: newCenter?.lng() ?? mapCenter.lng,
            });
        }
    };
    const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error("Error obtaining location:", error);
                    // Handle error or set a default location
                    setCurrentLocation(defaultCenter); // Default to New York City
                }
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
            // Set a default location
            setCurrentLocation(defaultCenter); // Default to New York City
        }
    }, []);

    const [reverseGeocodeResponse, setReverseGeocodeResponse] = useState<any | null>(null);
    const [reverseGeocodeButtonLoading, setReverseGeocodeButtonLoading] = useState(false);
    const doReverseGeocode = async () => {
        const reverseGeocode = await poiPGReverseGeocode({ location: { latitude: mapCenter.lat, longitude: mapCenter.lng } });
        if (reverseGeocode && reverseGeocode.data && reverseGeocode.data.geocode) {
            setReverseGeocodeResponse(reverseGeocode.data.geocode);
        }
    }
    const renderReverseGeocodeResponse = () => {
        if (!reverseGeocodeResponse || !reverseGeocodeResponse.display_name || !reverseGeocodeResponse.address) {
            return null;
        }
        return <div className="api-response">
            <h4>Reverse Geocode Response</h4>
            <div className="api-response-container">
                <p>Display Name: <span>{reverseGeocodeResponse.display_name}</span></p>

                {Object.keys(reverseGeocodeResponse.address).map((key) => {
                    return <p key={key} style={{ textTransform: "capitalize" }}>{key}: <span>{reverseGeocodeResponse.address[key]}</span></p>
                })}
            </div>
        </div>
    }

    useEffect(() => {
        const selectedWebsearchTopic = webSearchTopicOptions().find((option) => option.value === host.webSearchTopic);
        if (selectedWebsearchTopic) {
            setSelectedWebSearchTopic(selectedWebsearchTopic);
        } else {
            setSelectedWebSearchTopic(webSearchTopicOptions()[0]);
        }
    }, [host]);

    const [selectedWebSearchTopic, setSelectedWebSearchTopic] =
        useState<OptionType | null>();

    const applyReverseGeocodeToWebsearchTemplate = () => {
        if (!reverseGeocodeResponse || !reverseGeocodeResponse.address) {
            return;
        }
        const regex = /{.*}/gm;

        const replaceWith = [];
        if (reverseGeocodeResponse.address.suburb) {
            replaceWith.push(reverseGeocodeResponse.address.suburb);
        } else if (reverseGeocodeResponse.address.neighbourhood) {
            replaceWith.push(reverseGeocodeResponse.address.neighbourhood);
        }

        if (reverseGeocodeResponse.address.city) {
            replaceWith.push(reverseGeocodeResponse.address.city);
        } else if (reverseGeocodeResponse.address.town) {
            replaceWith.push(reverseGeocodeResponse.address.town);
        }

        if (reverseGeocodeResponse.address.state) {
            replaceWith.push(reverseGeocodeResponse.address.state);
        }

        if (reverseGeocodeResponse.address.country) {
            replaceWith.push(reverseGeocodeResponse.address.country);
        }

        const newQuery = originalWebSearchQueryTemplate.replace(regex, replaceWith.join(", "));
        setWebSearchQuery(newQuery);
    }
    const originalWebSearchQueryTemplate = host.webSearchQueryTemplate || "Interesting facts about {poi.name}, {poi.address}";
    const [webSearchQuery, setWebSearchQuery] = useState(originalWebSearchQueryTemplate);
    const [includeDomains, setIncludeDomains] = useState<string[]>(host.webSearchIncludeDomains || []);
    const [excludeDomains, setExcludeDomains] = useState<string[]>(host.webSearchExcludeDomains || []);

    const [loadingWebsearch, setLoadingWebsearch] = useState(false);
    const [webSearchResponse, setWebSearchResponse] = useState<any | null>(null);
    const doWebsearch = async () => {
        if (!selectedWebSearchTopic) {
            return;
        }
        const webSearch = await poiPGWebsearch({
            query: webSearchQuery,
            searchTopic: selectedWebSearchTopic.value,
            includeDomains,
            excludeDomains,
        })
        if (webSearch && webSearch.data?.search?.answer && webSearch.data?.search?.results) {
            setWebSearchResponse(webSearch.data.search);
        }
    };
    const renderWebsearchResponse = () => {
        if (!webSearchResponse) {
            return null;
        }
        return <div className="api-response">
            <h4>Websearch Response</h4>
            <div className="api-response-container">
                <p>Answer: <span>{webSearchResponse.answer}</span></p>
                <h5>Sources:</h5>
                {webSearchResponse.results.map((result: any, index: number) => {
                    return <div key={index} style={{ marginBottom: 20 }}>
                        <a href={result.url} target="_blank" rel="noreferrer">{result.title}</a>
                        <p>{result.content}</p>
                    </div>
                })}
            </div>
        </div>
    }

    const originalPromptSystem = host.promptTemplateHost;
    const [promptSystem, setPromptSystem] = useState(originalPromptSystem);
    const originalPromptUser = host.promptTemplatePoiMulti;
    const [promptUser, setPromptUser] = useState(originalPromptUser);

    const applyReverseGeocodeAndWebsearchToPrompts = () => {
        if (!reverseGeocodeResponse || !reverseGeocodeResponse.address) {
            return;
        }
        if (!webSearchResponse) {
            return;
        }
        let _promptSystem = originalPromptSystem || "";
        _promptSystem = _promptSystem.replaceAll(/{city}/g, reverseGeocodeResponse.address.city || reverseGeocodeResponse.address.town || "");
        _promptSystem = _promptSystem.replaceAll(/{country}/g, reverseGeocodeResponse.address.country || "");
        _promptSystem = _promptSystem.replaceAll(/{pois}/g, webSearchResponse.answer || "");
        setPromptSystem(_promptSystem);

        let _promptUser = originalPromptUser || "";
        _promptUser = _promptUser.replaceAll(/{pois}/g, webSearchResponse.answer || "");
        setPromptUser(_promptUser);
    }

    const [generateStoryButtonLoading, setGenerateStoryButtonLoading] = useState(false);
    const [storyResponse, setStoryResponse] = useState<any | null>(null);
    const doGenerateStory = async () => {
        if (!promptSystem || !promptUser) {
            return;
        }
        const story = await poiPGGenerateStory({ promptSystem, promptUser });
        if (story && story.data?.story) {
            setStoryResponse(story.data.story);
        }
    }
    const renderStoryResponse = () => {
        if (!storyResponse) {
            return null;
        }
        return <div className="api-response">
            <h4>Story Response</h4>
            <div className="api-response-container">
                <p className="story-response">{storyResponse}</p>
            </div>
        </div>
    }

    const originalPromptSystemFollowUpSuggestions = host.promptTemplateFollowUpSuggestions;
    const [promptSystemFollowUpSuggestions, setPromptSystemFollowUpSuggestions] = useState(originalPromptSystemFollowUpSuggestions);
    const originalPromptUserFollowUpSuggestions = "";
    const [promptUserFollowUpSuggestions, setPromptUserFollowUpSuggestions] = useState(originalPromptUserFollowUpSuggestions);
    const applyStoryToPromptsFollowUpSuggestions = () => {
        if (!storyResponse) {
            return;
        }
        setPromptUserFollowUpSuggestions(storyResponse);
    }
    const [generateFollowUpSuggestionsButtonLoading, setGenerateFollowUpSuggestionsButtonLoading] = useState(false);
    const [followUpSuggestionsResponse, setFollowUpSuggestionsResponse] = useState<any[] | null>(null);
    const doGenerateFollowUpSuggestions = async () => {
        if (!promptSystemFollowUpSuggestions || !promptUserFollowUpSuggestions) {
            return;
        }

        const followUpSuggestions = await poiPGGenerateFollowUpSuggestions({ promptSystem: promptSystemFollowUpSuggestions, promptUser: promptUserFollowUpSuggestions });
        if (followUpSuggestions && followUpSuggestions.data?.followUpSuggestions) {
            setFollowUpSuggestionsResponse(followUpSuggestions.data.followUpSuggestions);
        }

    }
    const renderFollowUpSuggestionsResponse = () => {
        if (!followUpSuggestionsResponse) {
            return;
        }
        return <div className="api-response">
            <h4>Follow Up Suggestions Response</h4>
            <div className="api-response-container">
                {followUpSuggestionsResponse.map((suggestion: any, index: number) => {
                    return <div key={index}>
                        <p>{index + 1}: <span>{suggestion}</span></p>
                    </div>
                })}
            </div>
        </div>
    }

    useEffect(() => {
        if (!storyResponse) {
            return;
        }
        const followUpStory = storyResponse;
        setPromptSystemFollowUpStory(followUpStory);
    }, [storyResponse])
    const [promptSystemFollowUpStory, setPromptSystemFollowUpStory] = useState("");
    const originalPromptUserFollowUpStory = host.promptTemplateFollowUp || "Now, let's dive deeper into the story of {follow_up_topic}.\n\nWhat are some interesting details about this topic that we haven't covered yet?";
    const [promptUserFollowUpStory, setPromptUserFollowUpStory] = useState(originalPromptUserFollowUpStory);
    const applyFollowUpSuggestionToPrompts = (suggestion: string) => {
        let _promptUser = originalPromptUserFollowUpStory || "";
        _promptUser = _promptUser.replaceAll(/{follow_up_topic}/g, suggestion);
        setPromptUserFollowUpStory(_promptUser);
    }
    const [generateFollowUpStoryButtonLoading, setGenerateFollowUpStoryButtonLoading] = useState(false);
    const [followUpStoryResponse, setFollowUpStoryResponse] = useState<any | null>(null);
    const doGenerateFollowUpStory = async () => {
        if (!promptSystemFollowUpStory || !promptUserFollowUpStory) {
            return;
        }
        const followUpStory = await poiPGGenerateStory({ promptSystem: promptSystemFollowUpStory, promptUser: promptUserFollowUpStory });
        if (followUpStory && followUpStory.data?.story) {
            setFollowUpStoryResponse(followUpStory.data.story);
        }
    }
    const renderFollowUpStoryResponse = () => {
        if (!followUpStoryResponse) {
            return;
        }
        return <div className="api-response">
            <h4>Follow Up Story Response</h4>
            <div className="api-response-container">
                <p className="story-response">{followUpStoryResponse}</p>
            </div>
        </div>
    }

    return <>
        <div className="row">
            <div>
                <small>
                    This playground is designed to be a tool for Admins to test Reverse Geocoding, Websearch and Story Generation. Currently the Playground only generates Text Stories without Audio as a cost saving measure.
                    <br /><br />
                    The guided path is to test a new type of story that foregoes the Google Points of Interests and just relies on the Websearch Results based on the user's location. All you have to do is to press the Big Green Buttons in order from top to bottom.
                    <br /><br />
                    The tool can be used for other testing purposes as well. The admin has full control of what is being sent to the AI models as all of the fields are sent as they are without further processing. Admins may freely edit any of the fields.
                    <br /><br />
                    Changes made in the playground will not be saved to the host.
                </small>
            </div>
            <div></div>
        </div>
        <hr />
        <h2>Reverse Geocode</h2>
        <div className="row row-wrap">
            <div>
                <LoadScript googleMapsApiKey={mapsApiKey}>
                    <GoogleMap
                        mapContainerStyle={{
                            width: "100%",
                            height: 600,
                            borderRadius: 8,
                            overflow: "hidden",
                        }}
                        onLoad={handleLoad}
                        onCenterChanged={handleCenterChanged}
                        center={currentLocation || defaultCenter}
                        zoom={10}
                    >
                    </GoogleMap>
                </LoadScript>
                <br />
                <LargeButton
                    text={"Reverse Geocode"}
                    icon={null}
                    color={"#34A853"}
                    loading={reverseGeocodeButtonLoading}
                    onClick={async function () {
                        setReverseGeocodeButtonLoading(true);
                        await doReverseGeocode();
                        setReverseGeocodeButtonLoading(false);
                    }} />
            </div>
            <div>
                {renderReverseGeocodeResponse()}
            </div>
        </div>

        <hr />
        <h2>Websearch</h2>
        <div className="row row-wrap">
            <div>
                <div>
                    <small>
                        Enter a prompt for Tavily Web Search.
                        <br /><br />
                        When applying the reverse geocode, all of the template variables will be removed and replaced with fields from the reverse geocode response:
                        <br />
                        <span style={{ color: "#34A853" }}>
                            {`{suburb OR neighborhood}, {city OR town}, {state}, {country}`}
                        </span>
                        <br />
                        <br />
                        This is just to help you get started with playing around with the prompting. You can manually edit the prompt as you see fit.
                    </small>
                </div>

                <h3>Query</h3>
                <LargeButton
                    text={"Apply Reverse Geocode to Websearch Template"}
                    icon={null}
                    color={"#34A853"}
                    disabled={!reverseGeocodeResponse}
                    onClick={applyReverseGeocodeToWebsearchTemplate}
                />
                <textarea
                    style={{
                        minHeight: 80,
                        overflow: "hidden",
                    }}
                    value={webSearchQuery}
                    placeholder="Example: Interesting facts about Red Hook, City of New York, New York, United States"
                    onChange={(e) => {
                        setWebSearchQuery(e.target.value);
                    }}
                ></textarea>

                <h3>Topic</h3>
                <Select
                    value={selectedWebSearchTopic}
                    onChange={(newValue) => {
                        setSelectedWebSearchTopic(newValue);
                    }}
                    options={webSearchTopicOptions()}
                    styles={dropdownStyles}
                />

                <h3>Include domains</h3>
                <small>
                    Enter a list of domains (urls) to include in the search.
                </small>
                <textarea
                    style={{
                        marginTop: 8,
                        minHeight: 45,
                        overflow: "hidden",
                    }}
                    value={includeDomains.join(" ")}
                    placeholder="example.com another.com"
                    onChange={(e) => {
                        setIncludeDomains(e.target.value.split(" "));
                    }}
                ></textarea>

                <h3>Exclude domains</h3>
                <small>
                    Enter a list of domains (urls) to exclude in the search.
                </small>
                <textarea
                    style={{
                        marginTop: 8,
                        minHeight: 45,
                        overflow: "hidden",
                    }}
                    value={excludeDomains.join(" ")}
                    placeholder="example.com another.com"
                    onChange={(e) => {
                        setExcludeDomains(e.target.value.split(" "));
                    }}
                ></textarea>
                <LargeButton
                    text={"Websearch"}
                    icon={null}
                    color={"#34A853"}
                    disabled={!webSearchQuery || !selectedWebSearchTopic || !reverseGeocodeResponse}
                    loading={loadingWebsearch}
                    onClick={async function () {
                        setLoadingWebsearch(true);
                        await doWebsearch();
                        setLoadingWebsearch(false);
                    }}
                    style={{ marginTop: 8 }}
                />
            </div>
            <div>
                {renderWebsearchResponse()}
            </div>
        </div>

        <hr />
        <h2>Story</h2>
        <div className="row row-wrap">
            <div>
                <div>
                    <small>
                        The guided Websearch Path tries to utilize the Host's "Host Definition"-Template and "Surprise Me"-Template, but since they are designed for slightly different usecase, the results may not be as expected. Feel free to adjust the prompts as you see fit.
                        <br /><br />
                        When applying the Reverse Geocoding and Websearch Results, template variables <span style={{ color: "#34A853" }}>{`{city}`}</span> and <span style={{ color: "#34A853" }}>{`{country}`}</span> will be filled from the Reverse Geocoding data and the Template Variable <span style={{ color: "#34A853" }}>{`{pois}`}</span> will be filled with the <span style={{ color: "#34A853" }}>answer</span> property from the Websearch Results.
                    </small>
                </div>

                <LargeButton
                    text={"Apply Reverse Geocode and Websearch to Prompts"}
                    icon={null}
                    color={"#34A853"}
                    style={{ marginTop: 8 }}
                    disabled={!reverseGeocodeResponse || !webSearchResponse}
                    onClick={applyReverseGeocodeAndWebsearchToPrompts}
                />
                <h3>System Prompt <small>("Persona Definition" Prompt)</small></h3>
                <textarea
                    style={{ minHeight: 500 }}
                    value={promptSystem}
                    onChange={(e) => {
                        setPromptSystem(e.currentTarget.value);
                    }}
                ></textarea>
                <h3>User Prompt <small>("Surprise Me" Prompt)</small></h3>
                <textarea
                    style={{ minHeight: 500 }}
                    value={promptUser}
                    onChange={(e) => {
                        setPromptUser(e.currentTarget.value);
                    }}
                ></textarea>
                <LargeButton
                    text={"Generate Story"}
                    icon={null}
                    color={"#34A853"}
                    style={{ marginTop: 8 }}
                    disabled={!promptSystem || !promptUser}
                    loading={generateStoryButtonLoading}
                    onClick={async () => {
                        setGenerateStoryButtonLoading(true);
                        await doGenerateStory();
                        setGenerateStoryButtonLoading(false);
                    }} />
            </div>
            <div>{renderStoryResponse()}</div>
        </div>

        <hr />
        <h2>Follow Up Suggestions</h2>
        <div className="row row-wrap">
            <div>
                <LargeButton
                    text={"Apply Story to Follow Up Suggestions Prompts"}
                    icon={null}
                    color={"#34A853"}
                    style={{ marginTop: 8 }}
                    disabled={!storyResponse}
                    onClick={applyStoryToPromptsFollowUpSuggestions}
                />
                <h3>System Prompt <small>("Extract Rabbithole Extension Points" Prompt)</small></h3>
                <small>
                    This prompt is used to extract N amount of topics from the
                    generated story. The topics are then presented to the user as
                    the options for follow up stories.
                    <br />
                    <br />
                    Variables in the prompt:
                    <span style={{ color: "#34A853" }}>
                        {" {"}n{"}"}
                    </span>
                </small>
                <br />
                <br />
                <textarea
                    style={{ minHeight: 500 }}
                    value={promptSystemFollowUpSuggestions}
                    onChange={(e) => {
                        setPromptSystemFollowUpSuggestions(e.currentTarget.value);
                    }}
                ></textarea>
                <h3>User Prompt <small>(Story Transcript)</small></h3>
                <textarea
                    style={{ minHeight: 500 }}
                    value={promptUserFollowUpSuggestions}
                    onChange={(e) => {
                        setPromptUserFollowUpSuggestions(e.currentTarget.value);
                    }}
                ></textarea>
                <LargeButton
                    text={"Generate Follow Up Suggestions"}
                    icon={null}
                    color={"#34A853"}
                    style={{ marginTop: 8 }}
                    disabled={!promptSystemFollowUpSuggestions || !promptUserFollowUpSuggestions}
                    loading={generateFollowUpSuggestionsButtonLoading}
                    onClick={async () => {
                        setGenerateFollowUpSuggestionsButtonLoading(true);
                        await doGenerateFollowUpSuggestions();
                        setGenerateFollowUpSuggestionsButtonLoading(false);
                    }} />
            </div>
            <div>{renderFollowUpSuggestionsResponse()}</div>
        </div>

        <hr />
        <h2>Follow Up Story</h2>
        <div className="row row-wrap">
            <div>
                {followUpSuggestionsResponse && followUpSuggestionsResponse.length > 0 &&
                    <>
                        <h5>Apply Follow Up Suggestion to Prompts</h5>
                        <div className="side-by-side">
                            {
                                followUpSuggestionsResponse.map((suggestion, index) => {
                                    const separator = index < followUpSuggestionsResponse.length - 1 ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>or</div> : null;

                                    return <React.Fragment key={index}>
                                        <LargeButton
                                            text={suggestion}
                                            icon={null}
                                            color={"#34A853"}
                                            style={{ marginTop: 8 }}
                                            onClick={() => {
                                                applyFollowUpSuggestionToPrompts(suggestion);
                                            }}
                                        />
                                        {separator}
                                    </React.Fragment>
                                })
                            }
                        </div>
                    </>
                }
                {!followUpSuggestionsResponse && (<LargeButton text={"Apply Follow Up Suggestion to Prompts"}
                    icon={null} color={"#34A853"} style={{ marginTop: 8 }}
                    disabled={true}
                    onClick={() => null}
                />)}
                <h3>System Prompt <small>(Story Transcript)</small></h3>
                <textarea
                    style={{ minHeight: 500 }}
                    value={promptSystemFollowUpStory}
                    onChange={(e) => {
                        setPromptSystemFollowUpStory(e.currentTarget.value);
                    }}
                ></textarea>
                <h3>User Prompt <small>(Rabbithole Continuation Prompt)</small></h3>
                <div>
                    <small>
                        This prompt is used when the user selects a follow up topic
                        after the initial story is generated. This prompt guides the
                        AI to generate a story about a specific follow up topic that
                        is extracted from the previous story in the rabbit hole.
                        <br />
                        <br />
                        Variables in the prompt:
                        <span style={{ color: "#34A853" }}>
                            {" {"}follow_up_topic{"}"}
                        </span>
                    </small>
                </div>
                <br />
                <textarea
                    style={{ minHeight: 500 }}
                    value={promptUserFollowUpStory}
                    onChange={(e) => {
                        setPromptUserFollowUpStory(e.currentTarget.value);
                    }}
                ></textarea>
                <LargeButton
                    text={"Generate Follow Up Story"}
                    icon={null}
                    color={"#34A853"}
                    style={{ marginTop: 8 }}
                    disabled={!promptSystemFollowUpStory || !promptUserFollowUpStory}
                    loading={generateFollowUpStoryButtonLoading}
                    onClick={async () => {
                        setGenerateFollowUpStoryButtonLoading(true);
                        await doGenerateFollowUpStory();
                        setGenerateFollowUpStoryButtonLoading(false);
                    }} />
            </div>
            <div>{renderFollowUpStoryResponse()}</div>
        </div>
    </>
}

export default HostPlaygroundWebsearch;