import { useCallback, useEffect, useState } from "react";
import { AxiosResponse, AxiosError } from "axios";
import instance from "./instance-poi";
import { ApiResponse, ApiResponseWithRefresh } from "../response";
import { getUserToken } from "../../firebase/firebase";

export function usePOIGet<T>(url: string): ApiResponseWithRefresh<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError | null>(null);

  const request = useCallback(async () => {
    try {
      const Authorization = await getUserToken();
      let response: AxiosResponse<T> = await instance.get(url, {
        headers: { Authorization },
      });
      setData(response.data);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [url]);

  useEffect(() => {
    request();
  }, [request, url]); // Initial request on page load

  const refresh = () => {
    request(); // Request data again when this function is called
  };

  return { data, loading, error, refresh };
}

export async function poiPost<T>(
  url: string,
  data: any,
  notJsonType?: boolean
): Promise<ApiResponse<T>> {
  try {
    const Authorization = await getUserToken();
    const response: AxiosResponse<T> = await instance.post(url, data, {
      headers: !notJsonType
        ? { Authorization }
        : { Authorization, "Content-Type": "application/octet-stream" },
    });

    return Promise.resolve({
      data: response.data,
      error: null,
      loading: false,
    });
  } catch (err: any) {
    return Promise.reject({ data: null, error: err, loading: false });
  }
}

export async function poiGet<T>(url: string): Promise<ApiResponse<T>> {
  try {
    const Authorization = await getUserToken();
    const response: AxiosResponse<T> = await instance.get(url, {
      headers: { Authorization },
    });
    return Promise.resolve({
      data: response.data,
      error: null,
      loading: false,
    });
  } catch (err: any) {
    return Promise.reject({ data: null, error: err, loading: false });
  }
}

export async function poiPut<T>(
  url: string,
  data: any,
  customHeaders: any
): Promise<ApiResponse<T>> {
  try {
    const Authorization = await getUserToken();
    const response: AxiosResponse<T> = await instance.put(url, data, {
      headers:
        customHeaders == null ||
        customHeaders === undefined ||
        Object.keys(customHeaders).length === 0
          ? { Authorization }
          : { ...customHeaders },
    });
    return Promise.resolve({
      data: response.data,
      error: null,
      loading: false,
    });
  } catch (err: any) {
    return Promise.reject({ data: null, error: err, loading: false });
  }
}

export async function poiDelete<T>(url: string): Promise<ApiResponse<T>> {
  try {
    const Authorization = await getUserToken();
    const response: AxiosResponse<T> = await instance.delete(url, {
      headers: { Authorization },
    });
    return Promise.resolve({
      data: response.data,
      error: null,
      loading: false,
    });
  } catch (err: any) {
    return Promise.reject({ data: null, error: err, loading: false });
  }
}
